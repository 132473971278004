<template>
  <div>
    <el-table
      v-if="pagination ? (isArray(data) ? true : data.data) : data"
      v-loading="loading"
      :data="filter()"
      style="width: 100%"
    >
      <el-table-column
        v-for="(field, index) in fields"
        :key="index"
        :prop="field.value"
        :label="field.label"
        :width="field.width ? field.width : 'auto'"
      />
      <el-table-column v-if="rules.length" width="150" align="right">
        <template v-slot="scope">
          <el-button
            v-for="(rule, index) in rules"
            :key="index"
            size="mini"
            :type="rule.type"
            :icon="rule.icon"
            class="margin-right"
            @click="handleClickAction(scope.$index, scope.row, rule.value)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="data && pagination"
      class="mt-4"
      background
      layout="prev, pager, next"
      :total="data.hasOwnProperty('meta') ? data.meta.total : 0"
      :page-size="data.hasOwnProperty('meta') ? data.meta.per_page : 0"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'GeneralTable',
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
    actions: {
      type: String,
      default: '',
    },
    data: {
      type: [Array, Object],
      required: true,
    },
    fields: {
      required: true,
      type: Array,
    },
    rules: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    searchField: {
      type: String,
      default: 'title',
    },
  },
  data: () => ({
    search: '',
  }),
  methods: {
    handleClickAction(_, row, value) {
      this.$emit(value, row);
    },
    handleCurrentChange(page) {
      this.$emit('reload', page);
    },
    filter() {
      const filterFnc = (value) =>
        !this.search || value[this.searchField].toLowerCase().includes(this.search.toLowerCase());
      if (this.pagination) {
        if (Array.isArray(this.data)) {
          return [];
        }
        return this.data.data.filter(filterFnc);
      }
      return this.data.filter(filterFnc);
    },
    isArray(obj) {
      return Array.isArray(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.margin-right {
  margin-right: 1rem;
}
</style>
