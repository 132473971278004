<template>
  <div :key="rerender">
    <div class="top-menu">
      <div class="content" />
      <div class="add-extra-menu-item">
        <el-button type="primary" icon="el-icon-plus" @click="handleAddExtraMenuItem">
          Добавить продукт
        </el-button>
      </div>
    </div>
    <general-table
      :loading="loading"
      :data="extraMenu"
      :fields="fields"
      :rules="tableRules"
      @reload="reloadData"
      @edit="handleEdit"
      @delete="handleDelete"
    />
    <el-dialog :title="title" :visible.sync="showModal" width="30%" :before-close="handleClose">
      <el-form ref="extraMenuItem" :model="extraMenuItem" :rules="formRules">
        <el-form-item label="Название" prop="title">
          <el-input v-model="extraMenuItem.title" autocomplete="off" />
        </el-form-item>
        <el-form-item label="Цена" prop="price">
          <el-input-number
            v-model="extraMenuItem.price"
            :precision="2"
            :step="0.1"
            :max="9999"
            class="price"
          />
        </el-form-item>
        <el-form-item label="Закупочная цена" prop="purchase_price">
          <el-input-number
            v-model="extraMenuItem.purchase_price"
            :precision="2"
            :step="0.1"
            :max="9999"
            class="price"
          />
        </el-form-item>
        <el-form-item label="Филиал">
          <el-autocomplete
            v-model="branch"
            style="width: 100%"
            :fetch-suggestions="searchBranchFn"
            :debounce="600"
            prefix-icon="el-icon-search"
            placeholder="Поиск филиала"
            value-key="title"
            @select="selectBranchFn"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModal = false">Закрыть</el-button>
        <el-button type="primary" :loading="loading" @click="submitForm('extraMenuItem')">
          {{ extraMenuItem.id ? 'Сохранить' : 'Добавить' }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import GeneralTable from '@/components/common/GeneralTable.vue';
import { mapActions, mapGetters } from 'vuex';
import { FETCH_BRANCHES } from '@/app/settings/branches/shared/state/types/action_types';
import { DATA as DATA_BRANCHES } from '@/app/settings/branches/shared/state/types/getter_types';

export default {
  name: 'ExtraMenu',
  components: { GeneralTable },
  data: () => ({
    temp: '',
    maxIndex: 5,
    branch: '',
    rerender: 0,
    currentID: 5,
    loading: false,
    showModal: false,
    title: 'Добавить продукт',
    extraMenu: [],
    extraMenuItem: {
      id: '',
      title: '',
      price: 0,
      purchase_price: 0,
    },
    fields: [
      { label: '#', value: 'id' },
      { label: 'Название', value: 'title' },
      { label: 'Цена', value: 'price' },
      { label: 'Закупочная цена', value: 'purchase_price' },
      { label: 'Филиал', value: 'branch.title' },
    ],
    formRules: {
      title: [{ required: true, message: 'Введите название', trigger: 'blur' }],
      price: [{ required: true, message: 'Введите цену', trigger: 'blur' }],
      purchase_price: [{ required: true, message: 'Введите закупочную цену', trigger: 'blur' }],
    },
    tableRules: [
      {
        value: 'edit',
        type: 'primary',
        icon: 'el-icon-edit',
      },
      {
        value: 'delete',
        type: 'danger',
        icon: 'el-icon-delete',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      branches: DATA_BRANCHES,
    }),
  },
  async mounted() {
    await this.$store.dispatch('FETCH_ADDITIONS');
    this.extraMenu = this.$store.getters.ADDITIONS;
  },
  methods: {
    ...mapActions({
      fetchBranches: FETCH_BRANCHES,
    }),
    handleEdit(item) {
      this.extraMenuItem = item;
      this.showModal = true;
      this.title = 'Редактирование категории';
    },
    async searchBranchFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString } } : {};
      await this.fetchBranches(filter);
      cb(this.branches.data);
    },
    selectBranchFn(item) {
      this.extraMenuItem.branch_id = item.id;
    },
    handleDelete(item) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          this.loading = true;
          try {
            await this.$store.dispatch('DELETE_ADDITIONS', item.id);
            this.$notify({
              title: 'Успешно',
              message: 'Успешно удален',
              duration: 1500,
              type: 'success',
            });
          } catch (error) {
            this.$notify.error({
              title: 'Ошибка',
              message: 'Ошибка',
              duration: 1500,
            });
          }
          this.loading = false;
        })
        .catch(() => {});
    },
    handleAddExtraMenuItem() {
      this.title = 'Добавить продукт';
      this.showModal = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sendDataExtraMenuItem();
          return true;
        }
        return false;
      });
    },
    async sendDataExtraMenuItem() {
      this.loading = true;
      if (this.extraMenuItem.id) {
        try {
          await this.$store.dispatch('UPDATE_ADDITIONS', this.extraMenuItem);
          this.$notify({
            title: 'Успешно',
            message: 'Успешно обновлен',
            duration: 1500,
            type: 'success',
          });
        } catch (error) {
          this.$notify.error({
            title: 'Ошибка',
            message: 'Ошибка',
            duration: 1500,
          });
        }
        this.handleClose();
        this.loading = false;
        return true;
      }
      try {
        await this.$store.dispatch('STORE_ADDITIONS', this.extraMenuItem);
        this.$notify({
          title: 'Успешно',
          message: 'Успешно добавлен',
          duration: 1500,
          type: 'success',
        });
      } catch (error) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Ошибка',
          duration: 1500,
        });
      }
      await this.$store.dispatch('FETCH_ADDITIONS');
      this.handleClose();
      this.loading = false;
      return true;
    },
    async reloadData(page) {
      await this.$store.dispatch('FETCH_ADDITIONS', page);
      this.extraMenu = this.$store.getters.ADDITIONS;
    },
    handleClose() {
      this.showModal = false;
      this.extraMenuItem = { id: '', title: '', price: '' };
      this.title = 'Добавить продукт';
    },
  },
};
</script>

<style lang="scss">
.top-menu {
  display: flex;
  justify-content: space-between;
}
.el-input-number {
  width: 100%;
}
</style>
